import React, { useEffect, useState } from 'react';
import useFirebase from 'vendor/Firebase';
import { useSetRecoilState } from 'recoil';
import { Button } from 'antd';
import {
  GalleryRow,
  GallerySectionName,
  GalleryThumbnail,
  GalleryThumbnailDetailsSeparator, GalleryThumbnailName,
  GalleryThumbnailWrapper, SectionNameWrapper,
  ShopGallery,
  ShopHeader, TypeGalleryWrapper,
} from './styledComponents';
import { Body, H3 } from '../shared/typography';
import { lightboxImageAtom, lightboxVisibleAtom } from '../shared/state/state';
import { IGalleryImage } from '../shared/types';
import Lightbox from '../components/Gallery/Lightbox';
import Logo from '../components/Logo/Logo';
import { SoldGraphicMed } from '../components/Gallery/SoldGraphic';
import { GallerySoldIndicator } from '../components/Gallery/styledComponents';
import Loader from '../components/Gallery/Loader';

const shortid = require('shortid');

export default () => {
  const { database } = useFirebase();
  const setLightboxVisible = useSetRecoilState(lightboxVisibleAtom);
  const setCurrentImage = useSetRecoilState(lightboxImageAtom);
  const [images, setImages] = useState([]);

  const onImageClick = (image: IGalleryImage) => (e: any) => {
    e.preventDefault();
    setCurrentImage(image);
    setLightboxVisible(true);
  };

  const GalleryThumbnails = (sectionName: string, thumbnails: IGalleryImage[]) => (
    <TypeGalleryWrapper key={shortid.generate()}>
      <SectionNameWrapper>
        <GallerySectionName>{sectionName}</GallerySectionName>
        <GalleryThumbnailDetailsSeparator />
      </SectionNameWrapper>
      <GalleryRow>
        {thumbnails.map((thumb: IGalleryImage) => (
          <GalleryThumbnailWrapper key={shortid.generate()}>
            {thumb.sold && <GallerySoldIndicator><SoldGraphicMed /></GallerySoldIndicator>}
            <GalleryThumbnail onClick={onImageClick(thumb)}>
              <img src={`images/${thumb.url}`} />
            </GalleryThumbnail>
            <GalleryThumbnailName>{thumb.title}</GalleryThumbnailName>
          </GalleryThumbnailWrapper>
        ))}
      </GalleryRow>
    </TypeGalleryWrapper>
  );

  useEffect(() => {
    database.ref('/').on('value', (snapshot) => {
      const { galleryItems, itemTypes } = snapshot.val();
      const itemGroups = itemTypes.map((type: string) =>
        ({ type, items: galleryItems.filter((item: IGalleryImage) => item.type === type) }));

      // @ts-ignore
      setImages(itemGroups.map((itemGroup) => GalleryThumbnails(itemGroup.type, itemGroup.items)));
    });
  }, []);

  return (
    <>
      <Lightbox />
      <ShopHeader>
        <Logo />
        <H3>Welcome to my shop of strange and wonderful things.</H3>
        <Body>
          {`All items you see here are customs, and most are for sale. If you have any questions, 
          click the button below to reach out.`}
          <br />
          <br />
          {`Models are made with Easton Aluminum arrow shaft tubing, nylon washers, stainless steel screws and 80 lb 
          Commercial grade monofilament line. The line is crimped to the washers in precise lengths to make a 
          continuous tension harness. The struts are then cut to length and screwed in place inside the harness.`}
        </Body>
        <Button
          style={{ marginTop: 16 }}
          key={shortid.generate()}
          type="primary"
          size="large"
          href="mailto:bruce@tensiondesigns.com?subject=Contact%20from%20your%20pop-up%20shop"
        >
          Contact Me
        </Button>
      </ShopHeader>
      <ShopGallery>
        {images.length > 0 && images}
        {images.length === 0 && <Loader />}
      </ShopGallery>
    </>
  );
};
