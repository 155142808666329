import React from 'react';

export default () => (
  <svg width="151" height="151" viewBox="0 0 151 151" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M64.9593 10.4534L73.6028 23.9645L81.3846 9.93941L86.3965 25.1756L97.4217 13.5264L98.4871 29.5303L112.063 20.9889L109.115 36.755L124.388 31.8581L117.612 46.3957L133.624 45.4511L123.445 57.8467L139.188 60.9137L126.246 70.3885L140.732 77.2743L125.841 83.233L138.16 93.5051L122.254 95.5732L131.631 108.586L115.711 106.634L121.557 121.57L106.623 115.719L108.571 131.64L95.5606 122.26L93.4882 138.164L83.2195 125.843L77.2568 140.733L70.3749 126.245L60.8966 139.184L57.8338 123.44L45.4355 133.616L46.3844 117.604L31.845 124.377L36.7459 109.105L20.979 112.049L29.524 98.475L13.5204 97.4054L25.1725 86.3833L9.93769 81.3673L23.9649 73.5892L10.4561 64.9421L25.9769 60.8968L15.0429 49.1619L31.0822 49.1035L23.4101 35.0181L38.9599 38.9504L35.0318 23.3995L49.1152 31.0753L49.1778 15.0361L60.9098 25.9732L64.9593 10.4534Z" fill="#F44336" />
    <path d="M38.9616 88.8298L41.0794 84.9343C42.396 85.6944 43.5974 86.2069 44.6837 86.4717C45.7699 86.7365 46.8688 86.781 47.9803 86.6051C49.0732 86.4321 49.899 86.1306 50.4575 85.7004C51.0346 85.2673 51.2762 84.7543 51.1824 84.1616C51.0974 83.6243 50.8184 83.2413 50.3454 83.0123C49.891 82.7804 49.1689 82.6573 48.1793 82.643L43.7814 82.5699C42.1628 82.5413 40.8542 82.1312 39.8555 81.3398C38.8568 80.5485 38.2446 79.4396 38.0189 78.0132C37.7228 76.1422 38.1937 74.5581 39.4315 73.2609C40.685 71.9423 42.5528 71.0865 45.0351 70.6937C46.48 70.465 47.9737 70.485 49.5163 70.7536C51.0774 71.0192 52.4534 71.4946 53.6441 72.1796L51.674 76.1087C50.5291 75.4733 49.4364 75.0481 48.396 74.833C47.3741 74.6149 46.3537 74.5865 45.3348 74.7478C44.3716 74.9002 43.6277 75.1793 43.1033 75.5851C42.5945 75.9694 42.3811 76.4209 42.4632 76.9396C42.5394 77.4213 42.7755 77.7732 43.1714 77.9953C43.5829 78.196 44.2065 78.2967 45.0421 78.2974L49.1767 78.3267C51.0946 78.327 52.6099 78.7233 53.7227 79.5157C54.854 80.305 55.5384 81.45 55.7758 82.9505C56.0865 84.9141 55.564 86.5918 54.2082 87.9836C52.871 89.3725 50.8871 90.2751 48.2566 90.6913C46.7006 90.9376 45.0991 90.8967 43.4522 90.5687C41.8238 90.2377 40.3269 89.6581 38.9616 88.8298Z" fill="#FAFAFA" />
    <path d="M77.4767 75.8136C77.6966 77.2029 77.6361 78.5607 77.2953 79.8868C76.9516 81.1945 76.3884 82.3754 75.6059 83.4297C74.8203 84.4654 73.8295 85.3438 72.6334 86.0647C71.4373 86.7857 70.1168 87.2605 68.6719 87.4891C67.227 87.7178 65.8244 87.6739 64.4641 87.3575C63.1039 87.0411 61.8903 86.5116 60.8233 85.769C59.7535 85.0079 58.8532 84.0585 58.1225 82.9209C57.3889 81.7648 56.9121 80.492 56.6923 79.1027C56.4724 77.7134 56.5344 76.3649 56.8781 75.0572C57.2189 73.7311 57.7821 72.5501 58.5676 71.5144C59.3501 70.4601 60.3395 69.5725 61.5356 68.8516C62.7317 68.1306 64.0522 67.6558 65.4971 67.4271C66.942 67.1985 68.3446 67.2424 69.7049 67.5588C71.0652 67.8752 72.2802 68.414 73.3501 69.1751C74.417 69.9177 75.3173 70.8671 76.0509 72.0232C76.7816 73.1608 77.2569 74.4243 77.4767 75.8136ZM72.5585 76.5919C72.4324 75.7953 72.1763 75.0763 71.7899 74.4349C71.4007 73.775 70.9164 73.2345 70.3371 72.8135C69.7763 72.3895 69.1405 72.0914 68.4296 71.919C67.7158 71.7282 66.9699 71.6943 66.1919 71.8174C65.4139 71.9406 64.7149 72.2031 64.0949 72.605C63.472 72.9884 62.95 73.4698 62.529 74.0491C62.1265 74.6255 61.842 75.2876 61.6756 76.0355C61.5062 76.7649 61.4845 77.5278 61.6105 78.3244C61.7366 79.1209 61.9942 79.8492 62.3835 80.5092C62.7698 81.1506 63.2434 81.6833 63.8042 82.1072C64.3835 82.5282 65.03 82.8342 65.7438 83.025C66.4547 83.1974 67.1991 83.222 67.9771 83.0988C68.7552 82.9757 69.4556 82.7225 70.0786 82.3391C70.6985 81.9371 71.2098 81.448 71.6122 80.8716C72.0333 80.2923 72.3285 79.638 72.4979 78.9086C72.6643 78.1607 72.6846 77.3884 72.5585 76.5919Z" fill="#FAFAFA" />
    <path d="M81.5569 85.1368L78.4789 65.6861L83.2582 64.9298L85.6679 80.1569L95.9212 78.5343L96.5896 82.7579L81.5569 85.1368Z" fill="#FAFAFA" />
    <path d="M99.2493 82.337L96.1712 62.8863L104.063 61.6375C105.489 61.4118 106.873 61.4586 108.215 61.778C109.554 62.0788 110.746 62.5927 111.791 63.3197C112.855 64.0438 113.751 64.9654 114.479 66.0844C115.204 67.185 115.675 68.4207 115.892 69.7915C116.103 71.1252 116.033 72.4274 115.684 73.698C115.334 74.9686 114.765 76.1125 113.977 77.1297C113.207 78.144 112.232 79.0009 111.051 79.7004C109.871 80.3999 108.567 80.8625 107.141 81.0882L99.2493 82.337ZM104.62 65.8787L101.619 66.3536L103.36 77.3571L106.361 76.8822C107.121 76.762 107.812 76.5102 108.435 76.1268C109.058 75.7434 109.572 75.2728 109.977 74.715C110.398 74.1357 110.695 73.4906 110.867 72.7797C111.04 72.0689 111.066 71.3337 110.946 70.5742C110.822 69.7962 110.569 69.0957 110.186 68.4728C109.799 67.8313 109.317 67.3001 108.737 66.8791C108.176 66.4552 107.541 66.157 106.83 65.9847C106.116 65.7938 105.379 65.7585 104.62 65.8787Z" fill="#FAFAFA" />
  </svg>
);

export const SoldGraphicSmall = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M34.5349 5.46509L39.1448 12.671L43.2951 5.19096L45.9681 13.3169L51.8482 7.10401L52.4164 15.6394L59.6569 11.084L58.0846 19.4926L66.2305 16.881L62.6164 24.6343L71.1559 24.1305L65.7271 30.7415L74.1236 32.3772L67.2214 37.4305L74.9473 41.1029L67.0052 44.2809L73.575 49.7593L65.0921 50.8623L70.0932 57.8025L61.6025 56.7612L64.7205 64.727L56.7555 61.6069L57.7945 70.0979L50.8556 65.095L49.7504 73.5776L44.2737 67.0063L41.0936 74.9476L37.4232 67.2207L32.3682 74.1216L30.7347 65.7247L24.1222 71.1516L24.6283 62.6123L16.874 66.2243L19.4878 58.0791L11.0788 59.6492L15.6361 52.4099L7.10085 51.8395L13.3153 45.961L5.19008 43.2858L12.6712 39.1375L5.46654 34.5257L13.7443 32.3682L7.91287 26.1096L16.4671 26.0785L12.3753 18.5662L20.6686 20.6635L18.5736 12.3697L26.0847 16.4634L26.1181 7.90918L32.3752 13.7423L34.5349 5.46509Z" fill="#F44336" />
    <path d="M20.6694 47.2658L21.7989 45.1883C22.5011 45.5936 23.1419 45.8669 23.7212 46.0082C24.3005 46.1494 24.8866 46.1731 25.4794 46.0793C26.0623 45.9871 26.5027 45.8263 26.8006 45.5968C27.1083 45.3658 27.2372 45.0923 27.1872 44.7761C27.1418 44.4896 26.9931 44.2853 26.7408 44.1632C26.4984 44.0395 26.1133 43.9738 25.5855 43.9662L23.24 43.9273C22.3768 43.912 21.6788 43.6933 21.1462 43.2712C20.6135 42.8491 20.287 42.2577 20.1667 41.497C20.0087 40.4991 20.2599 39.6543 20.9201 38.9624C21.5886 38.2592 22.5847 37.8028 23.9086 37.5933C24.6792 37.4713 25.4759 37.482 26.2986 37.6252C27.1312 37.7669 27.865 38.0204 28.5001 38.3857L27.4494 40.4812C26.8387 40.1424 26.256 39.9156 25.7011 39.8009C25.1561 39.6846 24.6119 39.6694 24.0685 39.7554C23.5547 39.8367 23.158 39.9856 22.8783 40.202C22.607 40.407 22.4932 40.6478 22.5369 40.9244C22.5776 41.1813 22.7035 41.369 22.9147 41.4875C23.1341 41.5945 23.4667 41.6482 23.9124 41.6486L26.1175 41.6642C27.1404 41.6644 27.9485 41.8757 28.542 42.2983C29.1454 42.7193 29.5104 43.3299 29.637 44.1302C29.8027 45.1774 29.5241 46.0722 28.801 46.8145C28.0878 47.5553 27.0297 48.0367 25.6268 48.2587C24.7969 48.39 23.9428 48.3682 23.0644 48.1932C22.1959 48.0167 21.3976 47.7076 20.6694 47.2658Z" fill="#FAFAFA" />
    <path d="M41.2108 40.3239C41.3281 41.0648 41.2958 41.789 41.1141 42.4963C40.9308 43.1937 40.6304 43.8235 40.213 44.3858C39.7941 44.9382 39.2657 45.4066 38.6277 45.7911C37.9898 46.1756 37.2855 46.4289 36.5149 46.5508C35.7443 46.6728 34.9963 46.6494 34.2708 46.4806C33.5453 46.3119 32.898 46.0295 32.329 45.6334C31.7584 45.2275 31.2783 44.7211 30.8886 44.1144C30.4973 43.4978 30.2431 42.819 30.1258 42.0781C30.0085 41.3371 30.0416 40.6179 30.2249 39.9205C30.4067 39.2132 30.707 38.5834 31.1259 38.031C31.5433 37.4687 32.071 36.9953 32.7089 36.6108C33.3468 36.2263 34.0511 35.973 34.8217 35.8511C35.5923 35.7291 36.3404 35.7525 37.0659 35.9213C37.7913 36.0901 38.4394 36.3774 39.01 36.7833C39.579 37.1794 40.0591 37.6857 40.4504 38.3023C40.8401 38.909 41.0936 39.5829 41.2108 40.3239ZM38.5878 40.739C38.5205 40.3141 38.3839 39.9307 38.1779 39.5886C37.9703 39.2366 37.712 38.9483 37.403 38.7238C37.1039 38.4977 36.7648 38.3387 36.3857 38.2468C36.005 38.145 35.6072 38.1269 35.1922 38.1926C34.7773 38.2582 34.4045 38.3983 34.0738 38.6126C33.7416 38.8171 33.4632 39.0738 33.2387 39.3828C33.024 39.6902 32.8723 40.0433 32.7835 40.4422C32.6932 40.8312 32.6816 41.2381 32.7489 41.663C32.8161 42.0878 32.9535 42.4762 33.1611 42.8282C33.3672 43.1703 33.6197 43.4544 33.9188 43.6805C34.2278 43.905 34.5726 44.0682 34.9533 44.17C35.3324 44.2619 35.7294 44.275 36.1444 44.2093C36.5593 44.1437 36.9329 44.0086 37.2651 43.8041C37.5958 43.5898 37.8684 43.3289 38.0831 43.0215C38.3076 42.7125 38.4651 42.3635 38.5554 41.9745C38.6442 41.5756 38.655 41.1638 38.5878 40.739Z" fill="#FAFAFA" />
    <path d="M43.3869 45.2962L41.7453 34.9225L44.2943 34.5192L45.5794 42.6403L51.0479 41.7749L51.4043 44.0275L43.3869 45.2962Z" fill="#FAFAFA" />
    <path d="M52.8229 43.803L51.1812 33.4293L55.39 32.7633C56.1507 32.6429 56.8889 32.6679 57.6045 32.8382C58.3185 32.9986 58.9543 33.2727 59.5119 33.6605C60.0794 34.0466 60.5572 34.5381 60.9453 35.135C61.3319 35.7219 61.583 36.381 61.6987 37.1121C61.8113 37.8234 61.7744 38.5179 61.5879 39.1955C61.4015 39.8732 61.098 40.4833 60.6775 41.0258C60.2669 41.5667 59.7467 42.0238 59.1171 42.3968C58.4875 42.7699 57.7924 43.0166 57.0316 43.137L52.8229 43.803ZM55.6872 35.0253L54.0867 35.2785L55.0154 41.1471L56.6159 40.8938C57.0209 40.8297 57.3896 40.6954 57.7218 40.4909C58.054 40.2865 58.3282 40.0355 58.5445 39.7379C58.769 39.429 58.9272 39.0849 59.0191 38.7058C59.111 38.3267 59.125 37.9346 59.0609 37.5295C58.9952 37.1146 58.8601 36.741 58.6556 36.4088C58.4496 36.0667 58.1921 35.7833 57.8831 35.5588C57.584 35.3327 57.2449 35.1737 56.8658 35.0818C56.4851 34.98 56.0923 34.9612 55.6872 35.0253Z" fill="#FAFAFA" />
  </svg>
);

export const SoldGraphicMed = () => (
  <svg width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M53.5291 8.47089L60.6744 19.6401L67.1074 8.04599L71.2506 20.6412L80.3648 11.0112L81.2454 24.2411L92.4682 17.1802L90.0311 30.2135L102.657 26.1655L97.0554 38.1832L110.292 37.4023L101.877 47.6494L114.892 50.1847L104.193 58.0172L116.168 63.7095L103.858 68.6354L114.041 77.1269L100.893 78.8366L108.644 89.5938L95.4838 87.9799L100.317 100.327L87.971 95.4908L89.5814 108.652L78.8262 100.897L77.1131 114.045L68.6242 103.86L63.6951 116.169L58.006 104.192L50.1706 114.888L47.6388 101.873L37.3895 110.285L38.1739 97.0491L26.1546 102.648L30.2061 90.0226L17.1721 92.4563L24.236 81.2354L11.0063 80.3512L20.6388 71.2396L8.04462 67.0931L19.6404 60.6632L8.47314 53.5149L21.3037 50.1708L12.2649 40.4699L25.524 40.4216L19.1818 28.7777L32.0363 32.0284L28.7891 19.173L40.4313 25.5183L40.4831 12.2592L50.1816 21.3006L53.5291 8.47089Z" fill="#F44336" />
    <path d="M32.0376 73.262L33.7884 70.0418C34.8767 70.6701 35.8699 71.0937 36.7679 71.3127C37.6658 71.5316 38.5742 71.5683 39.4931 71.4229C40.3966 71.28 41.0792 71.0307 41.5409 70.6751C42.0179 70.317 42.2177 69.893 42.1401 69.4029C42.0699 68.9589 41.8392 68.6422 41.4482 68.4529C41.0726 68.2612 40.4757 68.1594 39.6576 68.1476L36.022 68.0872C34.684 68.0635 33.6022 67.7245 32.7766 67.0703C31.951 66.4161 31.4449 65.4994 31.2583 64.3203C31.0136 62.7736 31.4028 61.4641 32.4261 60.3918C33.4623 59.3017 35.0063 58.5942 37.0584 58.2695C38.2528 58.0805 39.4877 58.097 40.7629 58.319C42.0534 58.5386 43.1908 58.9316 44.1752 59.4979L42.5465 62.7459C41.6 62.2207 40.6968 61.8692 39.8367 61.6913C38.9919 61.5111 38.1484 61.4876 37.3062 61.6209C36.5099 61.7469 35.8949 61.9776 35.4614 62.3131C35.0408 62.6308 34.8644 63.004 34.9323 63.4328C34.9953 63.831 35.1904 64.1219 35.5177 64.3055C35.8579 64.4715 36.3734 64.5547 37.0642 64.5553L40.4821 64.5795C42.0675 64.5797 43.3202 64.9074 44.2401 65.5623C45.1753 66.2149 45.7411 67.1614 45.9374 68.4018C46.1942 70.025 45.7623 71.4119 44.6415 72.5625C43.536 73.7107 41.896 74.4568 39.7215 74.8009C38.4352 75.0045 37.1113 74.9707 35.7498 74.6995C34.4037 74.4259 33.1663 73.9468 32.0376 73.262Z" fill="#FAFAFA" />
    <path d="M63.8768 62.502C64.0586 63.6505 64.0086 64.7729 63.7268 65.8692C63.4427 66.9501 62.9771 67.9264 62.3302 68.7979C61.6808 69.6541 60.8618 70.3802 59.873 70.9762C58.8842 71.5722 57.7926 71.9647 56.5981 72.1538C55.4037 72.3428 54.2442 72.3065 53.1197 72.0449C51.9952 71.7834 50.992 71.3456 50.11 70.7318C49.2256 70.1026 48.4813 69.3177 47.8773 68.3773C47.2708 67.4216 46.8767 66.3695 46.695 65.221C46.5132 64.0724 46.5644 62.9577 46.8486 61.8767C47.1303 60.7804 47.5959 59.8042 48.2452 58.948C48.8921 58.0764 49.71 57.3427 50.6988 56.7467C51.6876 56.1507 52.7792 55.7582 53.9736 55.5692C55.1681 55.3801 56.3276 55.4164 57.4521 55.678C58.5766 55.9396 59.581 56.3849 60.4654 57.0141C61.3474 57.628 62.0917 58.4128 62.6981 59.3686C63.3022 60.309 63.6951 61.3534 63.8768 62.502ZM59.8111 63.1454C59.7068 62.4869 59.4951 61.8925 59.1757 61.3623C58.8539 60.8167 58.4536 60.3699 57.9747 60.0219C57.5111 59.6714 56.9855 59.4249 56.3978 59.2825C55.8078 59.1247 55.1912 59.0967 54.548 59.1985C53.9048 59.3003 53.327 59.5173 52.8145 59.8495C52.2995 60.1665 51.868 60.5644 51.52 61.0433C51.1873 61.5198 50.9521 62.0671 50.8145 62.6854C50.6744 63.2884 50.6565 63.9191 50.7607 64.5776C50.8649 65.236 51.0779 65.8381 51.3997 66.3836C51.7191 66.9139 52.1106 67.3542 52.5741 67.7047C53.053 68.0527 53.5875 68.3056 54.1776 68.4634C54.7652 68.6059 55.3806 68.6262 56.0238 68.5244C56.667 68.4227 57.246 68.2133 57.761 67.8964C58.2735 67.5641 58.6961 67.1597 59.0288 66.6833C59.3769 66.2044 59.6209 65.6634 59.7609 65.0605C59.8985 64.4422 59.9153 63.8038 59.8111 63.1454Z" fill="#FAFAFA" />
    <path d="M67.2498 70.2092L64.7053 54.1299L68.6562 53.5047L70.6481 66.0924L79.1242 64.7511L79.6767 68.2426L67.2498 70.2092Z" fill="#FAFAFA" />
    <path d="M81.8754 67.8947L79.3309 51.8154L85.8545 50.7831C87.0336 50.5965 88.1778 50.6352 89.287 50.8992C90.3937 51.1478 91.3792 51.5727 92.2435 52.1737C93.1231 52.7723 93.8637 53.5341 94.4653 54.4592C95.0645 55.369 95.4537 56.3905 95.633 57.5237C95.8075 58.6263 95.7503 59.7027 95.4613 60.7531C95.1723 61.8034 94.7019 62.749 94.0501 63.5899C93.4136 64.4284 92.6074 65.1368 91.6316 65.715C90.6557 66.2933 89.5781 66.6757 88.399 66.8623L81.8754 67.8947ZM86.3151 54.2891L83.8343 54.6817L85.2738 63.778L87.7546 63.3854C88.3825 63.286 88.9539 63.0779 89.4688 62.7609C89.9837 62.444 90.4088 62.0549 90.7439 61.5938C91.092 61.1149 91.3372 60.5816 91.4797 59.994C91.6221 59.4063 91.6437 58.7986 91.5443 58.1707C91.4425 57.5275 91.2332 56.9485 90.9162 56.4336C90.5969 55.9033 90.1977 55.4642 89.7188 55.1161C89.2553 54.7657 88.7296 54.5192 88.142 54.3767C87.5519 54.219 86.943 54.1898 86.3151 54.2891Z" fill="#FAFAFA" />
  </svg>
);
