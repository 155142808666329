import React from 'react';

export default () => (
  <svg width="254" height="152" viewBox="0 0 254 152" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.99983 124.653V140.078H6.28007V124.653H2.13623V122.103H13.132V124.653H8.99983Z" fill="#53A5BB" />
    <path d="M27.4176 124.653H20.1922V128.972H27.2075V131.521H20.1922V137.529H27.4176V140.078H17.4724V122.103H27.4176V124.653Z" fill="#53A5BB" />
    <path d="M32.902 140.078V120.88L46.0455 134.595V122.103H48.7653V141.173L35.6217 127.493V140.078H32.902Z" fill="#53A5BB" />
    <path d="M65.1987 124.781L62.9926 126.085C62.5801 125.37 62.1872 124.905 61.8136 124.687C61.4245 124.439 60.9226 124.315 60.3078 124.315C59.553 124.315 58.9266 124.528 58.4285 124.955C57.9305 125.374 57.6815 125.902 57.6815 126.539C57.6815 127.416 58.3351 128.122 59.6425 128.657L61.4401 129.391C62.9031 129.981 63.9731 130.703 64.6501 131.556C65.3271 132.402 65.6656 133.442 65.6656 134.676C65.6656 136.33 65.1131 137.696 64.0081 138.774C62.8953 139.861 61.514 140.404 59.8643 140.404C58.3001 140.404 57.0083 139.943 55.9889 139.019C54.985 138.095 54.3586 136.795 54.1096 135.119L56.8644 134.513C56.9889 135.569 57.2068 136.299 57.518 136.702C58.0783 137.478 58.8954 137.866 59.9693 137.866C60.8175 137.866 61.5218 137.583 62.0821 137.017C62.6424 136.45 62.9225 135.732 62.9225 134.863C62.9225 134.513 62.872 134.195 62.7708 133.908C62.6774 133.613 62.5257 133.345 62.3156 133.105C62.1132 132.856 61.8486 132.627 61.5218 132.418C61.195 132.201 60.8059 131.995 60.3545 131.801L58.6153 131.079C56.1484 130.039 54.915 128.518 54.915 126.515C54.915 125.165 55.4325 124.036 56.4675 123.127C57.5025 122.212 58.7904 121.754 60.3312 121.754C62.4089 121.754 64.0315 122.763 65.1987 124.781Z" fill="#53A5BB" />
    <path d="M73.5779 122.103V140.078H70.8582V122.103H73.5779Z" fill="#53A5BB" />
    <path d="M78.9222 131.009C78.9222 128.479 79.8521 126.306 81.712 124.49C83.5641 122.673 85.7897 121.765 88.3888 121.765C90.9569 121.765 93.1591 122.681 94.9957 124.513C96.84 126.345 97.7621 128.545 97.7621 131.114C97.7621 133.699 96.8361 135.891 94.984 137.692C93.1241 139.5 90.879 140.404 88.2488 140.404C85.922 140.404 83.8326 139.601 81.9805 137.994C79.9416 136.217 78.9222 133.889 78.9222 131.009ZM81.6653 131.044C81.6653 133.031 82.3346 134.665 83.673 135.945C85.0037 137.226 86.5407 137.866 88.2838 137.866C90.1748 137.866 91.7701 137.214 93.0696 135.911C94.3692 134.591 95.019 132.984 95.019 131.091C95.019 129.174 94.377 127.567 93.093 126.271C91.8168 124.967 90.237 124.315 88.3538 124.315C86.4784 124.315 84.8948 124.967 83.603 126.271C82.3112 127.559 81.6653 129.15 81.6653 131.044Z" fill="#53A5BB" />
    <path d="M103.095 140.078V120.88L116.238 134.595V122.103H118.958V141.173L105.814 127.493V140.078H103.095Z" fill="#53A5BB" />
    <path d="M134.491 140.078V122.103H138.273C140.086 122.103 141.518 122.281 142.568 122.638C143.697 122.988 144.72 123.581 145.638 124.42C147.498 126.112 148.428 128.335 148.428 131.091C148.428 133.854 147.459 136.089 145.522 137.797C144.549 138.65 143.529 139.244 142.463 139.578C141.467 139.912 140.055 140.078 138.226 140.078H134.491ZM137.211 137.529H138.436C139.658 137.529 140.673 137.401 141.483 137.145C142.292 136.873 143.024 136.442 143.677 135.852C145.016 134.634 145.685 133.047 145.685 131.091C145.685 129.119 145.024 127.52 143.701 126.294C142.51 125.2 140.755 124.653 138.436 124.653H137.211V137.529Z" fill="#53A5BB" />
    <path d="M163.718 124.653H156.492V128.972H163.507V131.521H156.492V137.529H163.718V140.078H153.772V122.103H163.718V124.653Z" fill="#53A5BB" />
    <path d="M179.392 124.781L177.186 126.085C176.774 125.37 176.381 124.905 176.007 124.687C175.618 124.439 175.116 124.315 174.501 124.315C173.747 124.315 173.12 124.528 172.622 124.955C172.124 125.374 171.875 125.902 171.875 126.539C171.875 127.416 172.529 128.122 173.836 128.657L175.634 129.391C177.097 129.981 178.167 130.703 178.844 131.556C179.521 132.402 179.859 133.442 179.859 134.676C179.859 136.33 179.307 137.696 178.202 138.774C177.089 139.861 175.708 140.404 174.058 140.404C172.494 140.404 171.202 139.943 170.182 139.019C169.179 138.095 168.552 136.795 168.303 135.119L171.058 134.513C171.182 135.569 171.4 136.299 171.712 136.702C172.272 137.478 173.089 137.866 174.163 137.866C175.011 137.866 175.715 137.583 176.276 137.017C176.836 136.45 177.116 135.732 177.116 134.863C177.116 134.513 177.066 134.195 176.964 133.908C176.871 133.613 176.719 133.345 176.509 133.105C176.307 132.856 176.042 132.627 175.715 132.418C175.389 132.201 174.999 131.995 174.548 131.801L172.809 131.079C170.342 130.039 169.109 128.518 169.109 126.515C169.109 125.165 169.626 124.036 170.661 123.127C171.696 122.212 172.984 121.754 174.525 121.754C176.602 121.754 178.225 122.763 179.392 124.781Z" fill="#53A5BB" />
    <path d="M187.771 122.103V140.078H185.052V122.103H187.771Z" fill="#53A5BB" />
    <path d="M203.283 130.718H210.73V131.312C210.73 132.662 210.57 133.858 210.251 134.898C209.94 135.86 209.415 136.76 208.676 137.599C207.002 139.477 204.874 140.416 202.291 140.416C199.769 140.416 197.61 139.508 195.812 137.692C194.015 135.868 193.116 133.679 193.116 131.126C193.116 128.518 194.03 126.306 195.859 124.49C197.688 122.666 199.913 121.754 202.536 121.754C203.944 121.754 205.259 122.041 206.481 122.615C207.648 123.19 208.796 124.121 209.925 125.409L207.987 127.26C206.508 125.297 204.707 124.315 202.582 124.315C200.676 124.315 199.077 124.971 197.785 126.282C196.493 127.571 195.847 129.185 195.847 131.126C195.847 133.128 196.567 134.777 198.007 136.074C199.353 137.277 200.812 137.878 202.384 137.878C203.722 137.878 204.925 137.428 205.991 136.528C207.057 135.619 207.652 134.533 207.777 133.268H203.283V130.718Z" fill="#53A5BB" />
    <path d="M216.074 140.078V120.88L229.218 134.595V122.103H231.938V141.173L218.794 127.493V140.078H216.074Z" fill="#53A5BB" />
    <path d="M248.371 124.781L246.165 126.085C245.752 125.37 245.359 124.905 244.986 124.687C244.597 124.439 244.095 124.315 243.48 124.315C242.725 124.315 242.099 124.528 241.601 124.955C241.103 125.374 240.854 125.902 240.854 126.539C240.854 127.416 241.507 128.122 242.815 128.657L244.612 129.391C246.075 129.981 247.145 130.703 247.822 131.556C248.499 132.402 248.838 133.442 248.838 134.676C248.838 136.33 248.285 137.696 247.18 138.774C246.068 139.861 244.686 140.404 243.037 140.404C241.472 140.404 240.181 139.943 239.161 139.019C238.157 138.095 237.531 136.795 237.282 135.119L240.037 134.513C240.161 135.569 240.379 136.299 240.69 136.702C241.251 137.478 242.068 137.866 243.142 137.866C243.99 137.866 244.694 137.583 245.254 137.017C245.815 136.45 246.095 135.732 246.095 134.863C246.095 134.513 246.044 134.195 245.943 133.908C245.85 133.613 245.698 133.345 245.488 133.105C245.286 132.856 245.021 132.627 244.694 132.418C244.367 132.201 243.978 131.995 243.527 131.801L241.788 131.079C239.321 130.039 238.087 128.518 238.087 126.515C238.087 125.165 238.605 124.036 239.64 123.127C240.675 122.212 241.963 121.754 243.503 121.754C245.581 121.754 247.204 122.763 248.371 124.781Z" fill="#53A5BB" />
    <path d="M5.97656 103.196L79.1883 89.4117L118.409 108.039L5.97656 103.196Z" fill="#C8D8DE" />
    <path d="M113.179 46.1961L99.7324 37.2549H113.179V46.1961Z" fill="#3E9BB5" />
    <path d="M81.0559 90.1569L113.179 46.1961L99.7323 37.2549L78.8147 89.7843L81.0559 90.1569Z" fill="#9ED3E9" />
    <path d="M110.565 19L16.8088 103.569H24.2794L107.203 26.8235L110.565 19Z" fill="#3E9BB5" />
    <path d="M110.565 19L16.8089 103.569H2.24121L104.215 19H110.565Z" fill="#9ED3E9" />
    <path d="M50.4473 63.5908L51.3312 62.8672L57.3895 66.9752L56.5563 67.7462L50.4473 63.5908Z" fill="#3E9BB5" />
    <path d="M119.156 108.039L10.0852 17.5098L19.4234 16.3921L121.397 106.549L119.156 108.039Z" fill="#3E9BB5" />
    <path d="M119.156 108.039L10.0853 17.5098L0 28.3137L117.288 108.039H119.156Z" fill="#9ED3E9" />
  </svg>

);
