import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import theme from 'shared/theme';
import {
  BrowserRouter as Router, Route, Switch, useLocation,
} from 'react-router-dom';
import { BODY_SIZE } from 'shared/typography/constants';
import { Shop } from 'pages';
import { AuthProvider } from 'vendor/Firebase/AuthProvider';
import Login from 'pages/Auth/Login';
import PrivateRoute from './components/Routing/PrivateRoute';

const shortid = require('shortid');

const GlobalStyle = createGlobalStyle`
  html {
    font-size: ${BODY_SIZE}px;
  }
`;

const AppSections = () => {
  const location = useLocation();

  return (
    <>
      <Switch location={location} key={location.pathname}>
        <Route exact path="/entry">
          <Login key={shortid.generate()} />
        </Route>
        <PrivateRoute exact path="/shop" RouteComponent={Shop} />
        <PrivateRoute exact path="/" RouteComponent={Shop} />
      </Switch>
    </>
  );
};

const App = () => {
  const AppRouter = (
    <AuthProvider>
      <Router>
        <AppSections />
      </Router>
    </AuthProvider>
  );

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {AppRouter}
    </ThemeProvider>
  );
};

export default App;
