import { DefaultTheme } from 'styled-components';
import { GRID_UNIT } from './grid/constants';
import { hexWithAlpha, swatchRange } from './palette/utils';

const offWhite: string = '#FDFDFD';
const offBlack: string = '#0A0A0A';
const deviceSizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '451px',
  tablet: '768px',
  laptop: '1024px',
  laptopM: '1280px',
  laptopL: '1441px',
  desktop: '2560px',
};

const device = {
  mobileS: `(min-width: ${deviceSizes.mobileS})`,
  mobileM: `(min-width: ${deviceSizes.mobileM})`,
  mobileL: `(min-width: ${deviceSizes.mobileL})`,
  tablet: `(min-width: ${deviceSizes.tablet})`,
  laptop: `(min-width: ${deviceSizes.laptop})`,
  laptopM: `(min-width: ${deviceSizes.laptopM})`,
  laptopL: `(min-width: ${deviceSizes.laptopL})`,
  desktop: `(min-width: ${deviceSizes.desktop})`,
};

const theme: DefaultTheme = {
  deviceSizes,
  device,
  borderRadius: '2px',
  borderWidth: '1px',
  maxWidth: '1280px',
  minWidth: '1200px',
  easing: {
    transitionTiming: 'cubic-bezier(0.785, 0.135, 0.150, 0.860)',
    easeCurves: [0.785, 0.135, 0.150, 0.860],
  },
  palette: {
    neutral: {
      ...swatchRange(offBlack),
      ...{
        white: offWhite,
        black: offBlack,
        transparent: hexWithAlpha(offBlack, 0.0),
      },
    },
    primary: {
      // ...swatchRange('#0E6EB5'),
      ...swatchRange('#1890ff'),
      ...{ contrastText: offWhite },
    },
    secondary: {
      ...swatchRange('#1E131A'),
      ...{ contrastText: offWhite },
    },
    info: {
      ...swatchRange('#2196f3'),
      ...{ contrastText: offWhite },
    },
    warning: {
      ...swatchRange('#FF9800'),
      ...{ contrastText: offBlack },
    },
    success: {
      ...swatchRange('#4CAF50'),
      ...{ contrastText: offBlack },
    },
    error: {
      ...swatchRange('#F44336'),
      ...{ contrastText: offWhite },
    },
  },
  spacing: (factor: number, unit: string = 'px'): string => `${factor * GRID_UNIT}${unit}`,
  typography: {
    body: {
      fontFamily: 'Red Hat Text',
      size: {
        large: '18px',
        medium: '18px',
        small: '16px',
        xSmall: '16px',
      },
      fontWeight: 400,
      lineHeight: 1.2,
    },
    caption: {
      fontFamily: 'Red Hat Text',
      size: {
        large: '14px',
        medium: '14px',
        small: '14px',
        xSmall: '14px',
      },
      fontWeight: 400,
      lineHeight: 1.2,
    },
    h1: {
      fontFamily: 'Red Hat Display',
      size: {
        large: '88px',
        medium: '72px',
        small: '56px',
        xSmall: '48px',
      },
      fontWeight: 600,
      lineHeight: '96%',
    },
    h2: {
      fontFamily: 'Red Hat Display',
      size: {
        large: '64px',
        medium: '48px',
        small: '44px',
        xSmall: '40px',
      },
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: 'Red Hat Display',
      size: {
        large: '40px',
        medium: '32px',
        small: '28px',
        xSmall: '24px',
      },
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h4: {
      fontFamily: 'Red Hat Display',
      size: {
        large: '32px',
        medium: '28px',
        small: '24px',
        xSmall: '24px',
      },
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h6: {
      fontFamily: 'Red Hat Display',
      size: {
        large: '24px',
        medium: '22px',
        small: '20px',
        xSmall: '20px',
      },
      fontWeight: 500,
      lineHeight: 1.2,
    },
  },
  form: {
    strokeColor: {
      inactive: '#808080',
      active: '#020202',
    },
  },
};

export default theme;
