import styled from 'styled-components';
import theme from 'shared/theme';
import { FlexColumn, FlexRow } from '../shared/containers/FlexContainer';
import { Body } from '../shared/typography';

export const ShopHeader = styled(FlexColumn)`
  width: calc(100% - ${theme.spacing(6)});
  height: auto;
  
  align-items: flex-start;
  
  margin-top: ${theme.spacing(6)};
  margin-bottom: ${theme.spacing(6)};
  margin-left: ${theme.spacing(3)};
  
  & h3 {
    margin-top: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(1)};
  }
  @media ${theme.device.tablet} {
    width: 60%;
    margin-left: ${theme.spacing(10)};
    margin-bottom: ${theme.spacing(20)};
  }
  @media ${theme.device.laptop} {
    width: 40%;
  }
}
`;

export const ShopGallery = styled(FlexRow)`
    width: 100vw;
    min-height: 1000px;
    flex-wrap: wrap;
    gap: ${theme.spacing(8)};
    
    margin-bottom: ${theme.spacing(10)};
    @media ${theme.device.tablet} {
        padding: 0 ${theme.spacing(6)};
    }
`;

export const TypeGalleryWrapper = styled(FlexColumn)`
    gap: 24px;
    
    margin-bottom: ${theme.spacing(5)};
`;

export const SectionNameWrapper = styled(FlexColumn)`
    width: calc(100% - ${theme.spacing(6)});
    justify-content: center;
    align-items: flex-start;
    
    & p {
        margin: 0;
    }
`;

export const GallerySectionName = styled(Body)`
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 24px;
`;

export const GalleryRow = styled(FlexRow)`
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: ${theme.spacing(8.5)};
    padding: 0 ${theme.spacing(3)};

    @media ${theme.device.laptop} {
        padding-left: ${theme.spacing(6)};
        padding-right: 0;
    }
`;

export const GalleryThumbnailWrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    @media ${theme.device.tablet} {
        width: calc(50% - ${theme.spacing(4.5)});
    }
    @media ${theme.device.laptop} {
        width: calc(20% - ${theme.spacing(8)});
        align-items: flex-start;
    }
`;

export const GalleryThumbnailDetailsSeparator = styled.div`
    width: 100%;
    height: 1px;
    
    background-color: ${theme.palette.neutral[500]};
    
    margin: ${theme.spacing(1)} 0 ${theme.spacing(2)} 0;
`;

export const GalleryThumbnailName = styled(Body)`
    font-size: ${theme.spacing(2.5)};
    min-height: ${theme.spacing(5)};
    
    margin-top: ${theme.spacing(2)};
`;

export const GalleryThumbnail = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;
    opacity: 1;
    border: none;
    border-radius: 6px;
    
    box-shadow: 2px 2px 4px rgba(0,0,0,0.08), 1px 1px 0px rgba(0,0,0,0.18);
    
    padding: 0;
    
    &:focus {
        outline: none;
    }
    
    &:active {
        opacity: 0.8;
        & img {
            min-width: 99%;
            min-height: 99%;
            max-width: 99%;
            max-height: 99%;
        }
    }
    
    transition: opacity: 0.5s linear;
    
    & img {
      min-width: 100%;
      min-height: 100%;
      max-width: 100%;
      max-height: 100%;
      
      flex-shrink: 1;
      
      opacity: 1;
      
      padding-bottom: theme.spacing(1);
      
      transition: all 0.2s ease-in-out;
    }
`;
