import { atom } from 'recoil';
import { IGalleryImage } from '../types';

const shortid = require('shortid');

export const galleryImagesAtom = atom<IGalleryImage[]>({
  key: 'galleryImages',
  default: [],
});

export const lightboxImageAtom = atom<IGalleryImage|null>({
  key: 'lightboxImageAtom',
  default: null,
});

export const lightboxVisibleAtom = atom<boolean>({
  key: 'lightboxVisibleAtom',
  default: false,
});
