import React, { useCallback, useContext } from 'react';
import { withRouter, Redirect } from 'react-router';
import useFirebase from 'vendor/Firebase';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { Button, Input } from 'antd';
import { Body } from 'shared/typography';
import Logo from 'components/Logo/Logo';
import {
  DescriptionWrapper,
  FormComponent,
  FormItem,
  HeadPadding,
  HomePageLink,
  PasswordField,
  PasswordLabel,
  PasswordWrapper, SubmitButton,
} from './styledComponents';

const Login = ({ history }: { history: any }) => {
  const { auth } = useFirebase();
  const handleLogin = useCallback(
    async (values) => {
      const { password } = values;
      const username = 'keith.eric.hamilton@icloud.com';
      try {
        await auth().signInWithEmailAndPassword(username, password);
        history.push('/');
      } catch (error) {
        alert(error);
      }
    },
    [history],
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <HeadPadding />
      <DescriptionWrapper>
        <Logo />
        <Body>
          This gallery is password protected. Please enter the password given to you in the space below to continue.
        </Body>
        <HomePageLink>
          <a href="http://tensiondesigns.com">
            <br />
            Go to tensiondesigns.com instead
          </a>
        </HomePageLink>
      </DescriptionWrapper>
      <FormComponent

        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleLogin}
      >
        <PasswordWrapper justify="flex-start">
          <PasswordLabel>Password</PasswordLabel>
          <PasswordField
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </PasswordField>
        </PasswordWrapper>

        <FormItem>
          <SubmitButton type="primary" htmlType="submit">
            Submit
          </SubmitButton>
        </FormItem>
      </FormComponent>
    </>
  );
};

export default withRouter(Login);
