import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { lightboxImageAtom, lightboxVisibleAtom } from 'shared/state/state';
import { noScroll } from 'shared/scroll';
import {
  CloseOutlined,
} from '@ant-design/icons/lib';
import SoldGraphic from './SoldGraphic';
import {
  DetailsColumn,
  DetailsHeaderInnerWrapper,
  DetailsHeaderOuterWrapper,
  DetailThumbRow,
  InquiryButton,
  LightboxBackIcon,
  LightboxClose,
  LightboxDetailThumbImg,
  LightboxGalleryDetailThumb,
  LightboxGalleryImage,
  LightboxGalleryWrapper,
  LightboxImageRow,
  LightboxNavButton,
  LightboxNextIcon,
  BuyButton,
  SoldIndicator, WeightHeightDetails, MobileBuyButton, ColorWeightHeightDetails,
} from './styledComponents';
import { Body, H3, H6 } from '../../shared/typography';
import BuyGraphic from './BuyGraphic';
import { FlexRow } from '../../shared/containers/FlexContainer';

export default () => {
  const [currentImage, setCurrentImage] = useRecoilState(lightboxImageAtom);
  const [lightboxVisible, setLightboxVisible] = useRecoilState(lightboxVisibleAtom);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const onClose = () => {
    setLightboxVisible(false);

    setTimeout(() => {
      setCurrentImageIndex(0);
      setCurrentImage(null);
    }, 500);
  };
  const onEscPress = (e: any) => {
    if (e.keyCode === 27) {
      onClose();
    }
  };

  const onUpdateThumb = (index: number) => (e: any) => {
    setCurrentImageIndex(index);
  };

  const onLeft = (e: any) => {
    let index = currentImageIndex - 1;
    if (index < 0) {
      // @ts-ignore
      index = currentImage?.detailUrls.length - 1;
    }
    setCurrentImageIndex(index);
  };

  const onRight = (e: any) => {
    let index = currentImageIndex + 1;
    // @ts-ignore
    if (index > currentImage?.detailUrls.length - 1) index = 0;
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    window.addEventListener('keydown', onEscPress);
    window.addEventListener('scroll', noScroll);

    return function cleanup() {
      window.removeEventListener('keydown', onEscPress);
      window.removeEventListener('scroll', noScroll);
    };
  }, []);

  const onMobileBuyClick = (e: any) => {
    e.preventDefault();
    window.location.href = `mailto:bruce@tensiondesigns.com?subject=Inquiry%20about%20${currentImage?.title}`;
  };

  // @ts-ignore
  return (
    <>
      <LightboxGalleryWrapper lightboxVisible={lightboxVisible}>
        <LightboxClose onClick={onClose}>
          <CloseOutlined style={{ color: 'white', fontSize: 24 }} />
        </LightboxClose>
        <MobileBuyButton type="primary" onClick={onMobileBuyClick}>{`${currentImage?.sold ? 'Ask about' : 'Buy'} this model`}</MobileBuyButton>
        <DetailsColumn>
          <DetailsHeaderOuterWrapper>
            <DetailsHeaderInnerWrapper>
              {!currentImage?.sold
              && (
              <InquiryButton href={`mailto:bruce@tensiondesigns.com?subject=Inquiry%20about%20${currentImage?.title}`}>
                <BuyGraphic />
              </InquiryButton>
              )}
              <H3>{currentImage?.title}</H3>
              <H6>
                $
                {currentImage?.price}
              </H6>
            </DetailsHeaderInnerWrapper>
            <ColorWeightHeightDetails>
              <Body>
                {`Color: ${currentImage?.color}`}
              </Body>
              <WeightHeightDetails>
                <Body>
                  {`Height: ${currentImage?.height.toFixed(1)} cm`}
                </Body>
                <Body>
                  {`Weight: ${currentImage?.weight.toFixed(1)} g`}
                </Body>
              </WeightHeightDetails>
            </ColorWeightHeightDetails>
          </DetailsHeaderOuterWrapper>
          <DetailThumbRow>
            {!!currentImage && currentImage.detailUrls.map((url, i) => (
              <LightboxGalleryDetailThumb onClick={onUpdateThumb(i)}>
                <LightboxDetailThumbImg
                  selected={currentImageIndex === i}
                  src={`images/${currentImage?.detailUrls[i]}`}
                />
              </LightboxGalleryDetailThumb>
            ))}
          </DetailThumbRow>
          <BuyButton type="primary" onClick={onMobileBuyClick}>{`${currentImage?.sold ? 'Ask about' : 'Buy'} this model`}</BuyButton>
        </DetailsColumn>
        <LightboxImageRow>
          <LightboxNavButton type="link" shape="circle" icon={<LightboxBackIcon />} onClick={onLeft} />
          <LightboxGalleryImage>
            {currentImage?.sold && <SoldIndicator><SoldGraphic /></SoldIndicator>}
            <img src={`images/${currentImage?.detailUrls[currentImageIndex]}`} />
          </LightboxGalleryImage>
          <LightboxNavButton type="link" shape="circle" icon={<LightboxNextIcon />} onClick={onRight} />
        </LightboxImageRow>
      </LightboxGalleryWrapper>

    </>
  );
};
