import React, { useEffect, useState } from 'react';
import useFirebase from './index';

// @ts-ignore
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<any|null>(null);
  const [pending, setPending] = useState(true);

  const { auth } = useFirebase();

  useEffect(() => {
    auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setPending(false);
    });
  }, []);

  if (pending) {
    return (
      <>
        Loading...
      </>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
