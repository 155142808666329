import styled from 'styled-components';
import theme from 'shared/theme';
import { Button, Form } from 'antd';
import { Caption } from 'shared/typography';
import { FlexColumn } from '../../shared/containers/FlexContainer';

export const HeadPadding = styled.div`
    margin-top: ${theme.spacing(10)};
`;

export const PasswordWrapper = styled(FlexColumn)`
    align-items: flex-start;
    @media ${theme.device.laptop} {
        margin-left: ${theme.spacing(13)};
    }
`;

export const PasswordLabel = styled(Caption)`
    margin-bottom: 6px;
`;

export const PasswordField = styled(Form.Item)`
    width: 100%;
    
    @media ${theme.device.laptop} { 
        width: 65%;
    }
`;

export const DescriptionWrapper = styled.div`
    width: 80%;
    margin-left: 4%;
    margin-bottom: ${theme.spacing(8)};
    & p {
        color: ${theme.palette.neutral.black};
    }
    @media ${theme.device.laptop} {
        margin-left: ${theme.spacing(13)};
        width: ${theme.spacing(72)};
    }
`;

export const FormComponent = styled(Form)`
    width: 80%;
    margin-left: ${theme.spacing(2)};
    @media ${theme.device.laptop} {
        margin-left: unset;
        width: ${theme.spacing(72)};
    }
`;

export const FormItem = styled(Form.Item)`
    align-items: flex-start;
    & div {
        text-align: left;
        &:first-of-type {
            width: 20%;
        }
    }
`;

export const SubmitButton = styled(Button)`
    @media ${theme.device.laptop} {
        margin-left: ${theme.spacing(10)};
    }
`;

export const HomePageLink = styled(Caption)`
    margin-top: ${theme.spacing(2)};
    color: ${theme.palette.neutral.black};
`;
