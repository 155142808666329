import styled, { keyframes } from 'styled-components';
import { Button } from 'antd';
import { CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons/lib';
import { rgba } from '../../shared/palette/utils';
import theme from '../../shared/theme';
import { FlexColumn, FlexRow } from '../../shared/containers/FlexContainer';
import SoldGraphic from './SoldGraphic';
import { Body } from '../../shared/typography';

interface ILightboxGalleryWrapper {
  lightboxVisible: boolean;
}

interface ILightboxSelectedThumb {
  selected: boolean;
}

export const SoldIndicator = styled.div`
  width: auto;
  height: 150px;
  position: absolute;
  top: -35px;
  right: 35px;
  z-index: 10;
`;

export const GallerySoldIndicator = styled(SoldIndicator)`
  top: ${theme.spacing(-4)};
  right: ${theme.spacing(-3)};
  @media ${theme.device.laptop} {
    right: ${theme.spacing(-8.5)};
  }
`;

export const LightboxClose = styled.button`
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  
  display: flex;
  justify-content: center;
  align-items; center;
  
  cursor: pointer;
    outline: none;
    border: none;
    
    &:active {
      outline: none;
      border: none;
    }
    
  background-color: transparent;
  
  position: absolute;
  left: ${theme.spacing(4)};
  top: ${theme.spacing(4)};
  z-index: 20001;
  
  @media ${theme.device.tablet} {
    left: ${theme.spacing(10)};
  }
  @media ${theme.device.laptop} {
    left: ${theme.spacing(4)};
  }
`;

export const LightboxGalleryWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
    
    position: fixed;
    z-index: 20000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
    background-color: ${rgba(theme.palette.neutral.black, 0.95)};
    opacity: ${(props: ILightboxGalleryWrapper) => (props.lightboxVisible ? 1 : 0)};
    transform: ${(props: ILightboxGalleryWrapper) => (props.lightboxVisible ? 'scale(1)' : 'scale(0)')};
    transition: opacity 0.5s ease-in-out, transform 0s ease 0.5s;
    
    padding-top: ${theme.spacing(11)};
    
    @media ${theme.device.laptop} {
      flex-direction: row;
      justify-content: space-evenly;
      padding-top: unset;
    }
`;

export const LightboxGalleryImage = styled.div`
    width: fit-content;
    height: auto;
    max-width: calc(100% - 48px);
    max-height: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    & img {
      max-width: 100%;
      min-width: 100%;
      max-height: 100%;
      min-height: 100%;
      flex-shrink: 1;
    }
    
    @media ${theme.device.laptop} {
      max-width: 33vw
    }
`;

export const DetailsHeaderOuterWrapper = styled.div`
  width: 100%;
  margin-bottom: ${theme.spacing(2)};
  
  & h3 {
    margin-bottom: 2px;
  } 
`;

export const DetailsHeaderInnerWrapper = styled(DetailsHeaderOuterWrapper)`
  position: relative;
  border-bottom: 1px solid ${theme.palette.neutral.white};
`;

export const DetailsColumn = styled(FlexColumn)`
  width: 100%;
  
  justify-content: flex-start;
  align-items: flex-start;
  
  position: absolute;
  bottom: 0;
  z-index: 1000;
  
  background-color: ${rgba(theme.palette.neutral.black, 0.8)};
  backdrop-filter: blur(${theme.spacing(0.125)});
  
  padding: ${theme.spacing(2)} ${theme.spacing(3.5)};
  
  & h3 {
    color: ${theme.palette.neutral.white};
  }
  & h6 {
    color: ${theme.palette.neutral.white};
  }
  & p {
    width: 80%;
    color: ${theme.palette.neutral.white};
  }
  
  @media ${theme.device.tablet} {
    gap: ${theme.spacing(10)};
    padding: ${theme.spacing(1)} ${theme.spacing(6)};
  }
  @media ${theme.device.laptop} {
    width: 40%;
    height: 60%;
    
    position: unset;
    bottom: unset;
    z-index: unset;
    
    background-color: unset;
    backdrop-filter: unset;
    padding: 0;
  }
`;

export const ColorWeightHeightDetails = styled(FlexColumn)`
  align-items: flex-start;
  @media ${theme.device.laptop} {
    justify-content: flex-start;
    flex-direction: column;
    
    & p {
      margin-bottom: 6px;
    }
  }
`;

export const WeightHeightDetails = styled(FlexRow)`
  width: 100%;
  @media ${theme.device.laptop} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const DetailThumbRow = styled(FlexRow)`
  display: none;
  @media ${theme.device.laptop} {
    display: flex;
    justify-content: center;
    align-items; center;
    gap: ${theme.spacing(1)};
  }
`;

export const LightboxGalleryDetailThumb = styled.button`
    width: fit-content;
    height: auto;
    max-width: ${theme.spacing(10)};
    max-height: ${theme.spacing(10)};
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
    background-color: transparent; 
    cursor: pointer;
    outline: none;
    border: none;
    
    &:active {
      outline: none;
      border: none;
    }
`;

export const LightboxDetailThumbImg = styled.img`
  width: 100%;
  flex-shrink: 1;

  border: ${(props: ILightboxSelectedThumb) => `solid 2px ${props.selected ? theme.palette.primary.hue : 'transparent'}`};
`;

export const LightboxImageRow = styled(FlexRow)`
  gap: ${theme.spacing(3)};
`;

export const LightboxNavButton = styled(Button)`
  width: ${theme.spacing(5.5)};
  height: ${theme.spacing(5.5)};
  
  background-color: ${rgba(theme.palette.neutral.black, 0.8)};
  position: absolute;
  left: ${theme.spacing(1)};
  
  &:last-of-type {
    left: unset;
    right: ${theme.spacing(1)};
  }
  
  &:active {
    background-color: ${rgba(theme.palette.neutral.black, 0.8)};
  }
  
  &:hover{
    background-color: ${rgba(theme.palette.neutral.black, 0.8)};
  }
  
  @media ${theme.device.tablet} {
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    
    position: unset;
    left: unset;
    right: unset;
    background-color: unset;
    
    & span {
      font-size: ${theme.spacing(3)};
    }
  }
`;

export const LightboxBackIcon = styled(LeftOutlined)`
  font-size: ${theme.spacing(2.375)};
  
  @media ${theme.device.tablet} {
    font-size: ${theme.spacing(3.5)};
  }
`;

export const LightboxNextIcon = styled(RightOutlined)`
  & span {
    font-size: ${theme.spacing(2.375)};
  }
  
  @media ${theme.device.tablet} {
    font-size: ${theme.spacing(3.5)};
  }
`;

export const InquiryButton = styled.a`
  display: none;
  
  @media ${theme.device.desktop} {
    display: unset;
    position: absolute;
    top: ${theme.spacing(-10)};
    right: 0;
    background-color: transparent; 
    cursor: pointer;
    outline: none;
    border: none;
      
    &:active {
      outline: none;
      border: none;
    }
  }
`;

const rotation = keyframes`
  0% { transform: rotate(0deg) }
  100% { transform: rotate(359deg) }
`;

export const LoaderWrapper = styled(FlexColumn)`
  margin-top: ${theme.spacing(10)};
`;

export const LoaderText = styled(Body)`
  text-transform: uppercase;
  letter-spacing: 4px;
`;

export const IndefiniteLoader = styled.div`
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: 50%;
  
  border: 1px solid ${theme.palette.primary[200]};
  border-bottom-color: ${theme.palette.primary.hue};
  animation: ${rotation} 1s linear infinite; 
  
`;

export const BuyButton = styled(Button)`
  display: none;
  width: 100%;
  height: 48px;
  
  font-size: 20px;
  position: absolute;
  top: 24px;
  right: 24px;
  width: 50%;
  
  @media ${theme.device.tablet} {
    right: 74px;
  } 
  @media ${theme.device.laptop} {
  display: unset;
  position: unset;
  top: unset;
  right: unset;
  width: 244px;
  }
`;

export const MobileBuyButton = styled(BuyButton)`
  display: unset;
  @media ${theme.device.laptop} {
    display: none;
  }
`;
