import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from 'vendor/Firebase/AuthProvider';

const shortid = require('shortid');

export default ({ RouteComponent, exact, path }: { RouteComponent: any, exact: boolean, path: string}) => {
  const { currentUser } = useContext(AuthContext);
  return (
    <Route
      key={shortid.generate()}
      exact={exact}
      path={path}
      render={(routeProps) =>
        (currentUser ? (
        // @ts-ignore
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to="/entry" />
        ))}
    />
  );
};
